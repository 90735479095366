import React from "react"

export const YoutubeVideo = ({ videoId }) => {
  return (
    <iframe
      width="560"
      height="315"
      title="Youtube Video"
      src={"https://www.youtube.com/embed/" + videoId}
      frameBorder="0"
      style={{maxWidth: "100%"}}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen />
  )
}

export const YoutubeVideoCentered = ({videoId}) => {
  return (
    <div style={{textAlign: "center"}}>
      <YoutubeVideo videoId={videoId} />
    </div>
  )
}
